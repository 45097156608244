<template>
    <div class="exception-body notfound">
        <img src="/assets/layout/images/logo-white.svg" alt="diamond-layout" class="logo"/>

        <div class="exception-content">
            <div class="exception-title">Не найдено</div>
            <div class="exception-detail">Запрашиваемый ресурс не найден</div>
            <router-link
                :to="{name: loggedIn ? 'addNewBoard' : 'login'}">На главную
            </router-link>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
    setup () {
        const store = useStore()
        const loggedIn = computed(() => store.getters['auth/loggedIn'])

        return {
            loggedIn
        }
    }
}
</script>
